$('.my-navigation__item').click(function () {
    $('.my-navigation__item').removeClass('_active');
    $(this).addClass('_active');
    const thisDataValue = $(this).data('info');

    $('.my-info').each(function () {
        if (thisDataValue === $(this).data('info')) {
            $('.my-info').removeClass('_active');
            $(this).addClass('_active');
        }
    });
});
