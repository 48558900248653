$('.application__item._skill .tag').click(function () {
    const skillsTagText = $(this).text();

    $('.application__item._skill .bootstrap-tagsinput input').val(skillsTagText);
    $('.application__item._skill .bootstrap-tagsinput input').focus();
});

$('#skill, #skill-edit').tagsinput({
    tagClass: 'application__tag _application'
});

$('.application__item._price .tag').click(function () {
    const pricesTagText = $(this).text();

    $('#price').val(pricesTagText);
    $('#price').focus();
});
