$('.message__star').click(function () {
    $(this).toggleClass('_active');
});

$(function () {
    $('.message').each(function () {
        if ($(this).hasClass('_companion')) {
            $(this).nextUntil($('.message._you')).find('.message__avatar').addClass('_hide');
        } else if ($(this).hasClass('_you')) {
            $(this).nextUntil($('.message._companion')).find('.message__avatar').addClass('_hide');
        }
    });
});
