function checkActiveShareUser() {
    if ($('.share-user._active').length >= 1) {
        $('.share').addClass('_answering');
        $('.share-answer').addClass('_active');
    } else {
        $('.share').removeClass('_answering');
        $('.share-answer').removeClass('_active');
    }
}

$('.share-user').click(function () {
    $(this).toggleClass('_active');
    checkActiveShareUser();
});
