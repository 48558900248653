$('.close-application__grades-item').click(function () {
    const gradeValue = $(this).text();

    for (let i = 1; i <= 5; i++) {
        $('.close-application__grades-item').removeClass('_color-' + i);
    }

    $('.close-application__grades-item').removeClass('_active');
    $(this).addClass('_active _color-' + gradeValue);
    $(this).prevUntil().addClass('_color-' + gradeValue);
});

$('.close-application__grades-item').hover(function () {
    const gradeItem = $(this);
    const gradeValue = gradeItem.text();

    for (let i = 1; i <= 5; i++) {
        $('.close-application__grades-item').removeClass('_color-' + i);
    }

    gradeItem.addClass('_color-' + gradeValue);

    gradeItem.prevUntil().addClass('_color-' + gradeValue);
});

$('.close-application__grades').mouseout(function () {
    const gradeValueActive = $('.close-application__grades-item._active').text();

    if ($('.close-application__grades-item').hasClass('_active')) {

        for (let i = 1; i <= 5; i++) {
            $('.close-application__grades-item').removeClass('_color-' + i);
        }

        $('.close-application__grades-item._active').addClass('_color-' + gradeValueActive);
        $('.close-application__grades-item._active').prevUntil().addClass('_color-' + gradeValueActive);
    } else {
        for (let i = 1; i <= 5; i++) {
            $('.close-application__grades-item').removeClass('_color-' + i);
        }
    }
});

