function openApplication(e) {
    e.preventDefault();
    $('body').addClass('_no-scroll');
    $('.pop-ups__overlay').addClass('_active');
    $('#new-application').addClass('_active');
}

function editApplication(e) {
    e.preventDefault();
    $('body').addClass('_no-scroll');
    $('.pop-ups__overlay').addClass('_active');
    $('#edit-application').addClass('_active');
}

function closeOverlay(e) {
    e.preventDefault();
    $('body').removeClass('_no-scroll');
    $('.application').removeClass('_active');
    $('.pop-ups__overlay').removeClass('_active');
}

$('.add-application').click(function (e) {
    openApplication(e);
});

$('#edit-application-button, .event-full__edit').click(function (e) {
    editApplication(e);
});

$('.pop-ups__overlay, .application__close').click(function (e) {
    closeOverlay(e);
});
