$(function () {
    if ($('.navigation__container').length > 0 && !$('.navigation__container').hasClass('_fixed')) {
        const navigationTopPos = $('.navigation__container').offset().top;
        $(window).scroll(function () {
            const top = $(document).scrollTop();
            if (top > navigationTopPos) {
                $('.navigation__container').addClass('_fixed');
            } else {
                $('.navigation__container').removeClass('_fixed');
            }
        });
    }
});
