$('.edit__date-item').click(function () {
    $(this).toggleClass('_active');
    $(this).find('.edit__dropdown').toggleClass('_active');
});

$('.edit__skills-input').click(function () {
    $(this).toggleClass('_active');
    $(this).parent().find('.edit__dropdown').toggleClass('_active');
});

$('.edit__date-item .edit__dropdown-item').click(function () {
    const dropdownItemText = $(this).text();
    $(this).parent().parent().find('.edit__date-value').text(dropdownItemText);
});

$('.edit__skills-container .edit__dropdown-item').click(function () {
    const dropdownItemText = $(this).text();
    $(this).parent().parent().find('.edit__skills-input').val(dropdownItemText);
    $(this).parent().parent().find('.edit__skills-input').toggleClass('_active');
    $(this).parent().toggleClass('_active');
});

$('.edit__gender-item').click(function () {
    $('.edit__gender-item').removeClass('_active');
    $(this).addClass('_active');
});
