
$(function () {
    $('.attach__dropzone').dropzone({
        url: '/file',
        thumbnailWidth: null,
        thumbnailHeight: null,
        previewsContainer: '.attach__files-container',
        acceptedFiles: 'image/jpeg,image/png',
        createImageThumbnails: true,
        init: function () {
            this.on('addedfile', function () {
                $('.attach__files-content').hide();
                $('.attach__files-container').addClass('_added');
            });
        }
    });
});
