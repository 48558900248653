$('#answer-button').click(function (e) {
    e.preventDefault();
    $('.event-full__items').addClass('_answering');
    $('.event-full__item._hide-on-answer').addClass('_hide');
    $('.event-full__form._new-answer').removeClass('_hide');
    $('.event-full__textarea').focus();
});

$('#cancel-buton').click(function (e) {
    e.preventDefault();
    $('.event-full__form._new-answer').addClass('_hide');

    setTimeout(function () {
        $('.event-full__items').removeClass('_answering');
        $('.event-full__item._hide-on-answer').removeClass('_hide');
    }, 250);
});
