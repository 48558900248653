$(function () {
    const formTopPos = $('.navigation').height() - $('.form__container').height();

    $(window).scroll(function () {
        if ($(document).scrollTop() > formTopPos) {
            $('.events__form .form__container').addClass('_fixed');
            $('.events__form .form__container._fixed').css('top', $('.navigation').height());
        } else {
            $('.events__form .form__container._fixed').css('top', 0);
            $('.events__form .form__container').removeClass('_fixed');
        }
    });
});

$(function () {
    const formTopPos = $('.navigation').height() - 4;

    $(window).scroll(function () {
        if ($(document).scrollTop() > formTopPos) {
            $('.form._users .form__container').addClass('_fixed');
            $('.form._users .form__container._fixed').css('top', $('.navigation').height());
        } else {
            $('.form._users .form__container._fixed').css('top', 0);
            $('.form._users .form__container').removeClass('_fixed');
        }
    });
});

$(function () {
    const formTopPos = $('.navigation').height() - 64;

    $(window).scroll(function () {
        if ($(document).scrollTop() > formTopPos) {
            $('.form._friends .form__container').addClass('_fixed');
            $('.form._friends .form__container._fixed').css('top', $('.navigation').height());
        } else {
            $('.form._friends .form__container._fixed').css('top', 0);
            $('.form._friends .form__container').removeClass('_fixed');
        }
    });
});
