const userOldAnswer = $('.user-answer._editable .user-answer__desc').html();

function userAnswerEdit() {
    const userAnswerContent = $('.user-answer._editable .user-answer__desc').html();
    const userAnswerTextarea = $('<textarea class="user-answer__textarea" />');
    const descHeight = $('.user-answer._editable .user-answer__desc').height();
    const saveButton = $('<span id="save" onClick="userAnswerSave()" class="user-answer__button button">Сохранить</span>');
    const cancelButton = $('<span id="cancel" onClick="userAnswerCancel()" class="user-answer__button button _gray">Отмена</span>');

    $('#edit').replaceWith(saveButton);
    $('#decline').replaceWith(cancelButton);

    $('.user-answer._editable .user-answer__desc').replaceWith(userAnswerTextarea);
    $('.user-answer__textarea').height(descHeight);
    $('.user-answer__textarea').val(userAnswerContent);
    userAnswerTextarea.focus();
}

function userAnswerSave() {
    const userAnswerTextareaValue = $('.user-answer__textarea').val();
    const userAnswerContentBlock = $('<div class="user-answer__desc" />');
    const editButton = $('<span id="edit"  onClick="userAnswerEdit()" class="user-answer__button button">Редактировать</span>');
    const declineButton = $('<span id="decline" class="user-answer__button button _gray">Отказаться</span>');

    userAnswerContentBlock.text(userAnswerTextareaValue);
    $('.user-answer__textarea').replaceWith(userAnswerContentBlock);

    $('#save').replaceWith(editButton);
    $('#cancel').replaceWith(declineButton);
}

function userAnswerCancel() {
    const userAnswerContentBlock = $('<div class="user-answer__desc" />');
    const editButton = $('<span id="edit" onClick="userAnswerEdit()" class="user-answer__button button">Редактировать</span>');
    const declineButton = $('<span id="decline" class="user-answer__button button _gray">Отказаться</span>');

    userAnswerContentBlock.text(userOldAnswer);
    $('.user-answer__textarea').replaceWith(userAnswerContentBlock);

    $('#cancel').replaceWith(declineButton);
    $('#save').replaceWith(editButton);
}

$('#edit').click(userAnswerEdit);

$('#save').click(userAnswerSave);

$('#cancel').click(userAnswerCancel);

$(function () {
    $('.user-answer__rating, .user__grade, .profile-events__grade, .my-profile__rating, .my-reviews__grade, .my-reviews__grade, .my-rating__main-item b, .pick-performer__grade').each(function () {
        const ratingValue = eval($(this).text());
        const ratingMathValue = Math.floor(ratingValue * 1) / 1;

        switch (ratingMathValue) {
            case 1:
                $(this).addClass('_color-1');
                break;
            case 2:
                $(this).addClass('_color-2');
                break;
            case 3:
                $(this).addClass('_color-3');
                break;
            case 4:
                $(this).addClass('_color-4');
                break;
            case 5:
                $(this).addClass('_color-5');
                break;
            default:
                break;
        }
    });
});

$(function () {
    $('.event-customer__grade').each(function () {
        const ratingValue = eval($(this).text());
        const ratingMathValue = Math.floor(ratingValue * 1) / 1;

        switch (ratingMathValue) {
            case 1:
                $(this).addClass('_color-1');
                break;
            case 2:
                $(this).addClass('_color-2');
                break;
            case 3:
                $(this).addClass('_color-3');
                break;
            case 4:
                $(this).addClass('_color-4');
                break;
            case 5:
                $(this).addClass('_color-5');
                break;
            default:
                break;
        }
    });
});
