$('.messages-panel__input').keyup(function () {
    if ($(this).val().length > 0) {
        $(this).parent().find('.messages-panel__send').addClass('_active');
    } else {
        $(this).parent().find('.messages-panel__send').removeClass('_active');
    }
});

$('.messages-panel__attach').mouseenter(function () {
    $('.messages-panel__attach-items').addClass('_active');
});

$('.messages-panel__attach').mouseleave(function () {
    $('.messages-panel__attach-items').removeClass('_active');
});
