$(function () {
    const windowHeight = $(window).height() - 26;
    const bodyPadding = $('.messages__footer').height() + 48;
    $('.messages').height(windowHeight);
    $('.messages__body').css('padding-bottom', bodyPadding);
});

$(window).resize(function () {
    const windowHeight = $(window).height() - 26;
    const bodyPadding = $('.messages__footer').height() + 48;
    $('.messages').height(windowHeight);
    $('.messages__body').css('padding-bottom', bodyPadding);
});

$(function () {
    if ($('.messages__body').length > 0) {
        $(window).scrollTop($(document).height());
    }
});
